import React from "react"
import Layout from "../components/layout"
import "../components/layout.css"

const Contact = () => {
  return (
    <Layout>
      <div className="terms__container">
        <div className="terms">
          <h1>Feel free to reach out!</h1>
          <p>
            If you have any questions or business inquiries, please reach out
            via email to{" "}
            <a href="mailto:contactbriandesign@gmail.com">
              contactbriandesign@gmail.com
            </a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
